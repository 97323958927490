import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"

import AddCarLastService from "../car/add-car-last-service";
import {Link, useIntl} from "gatsby-plugin-intl";


class BodyClass extends React.Component {
  static propTypes = {
    name: React.PropTypes
  }
  static defaultProps = {
    name: ''
  }
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const LastService = ({location}) => {
  const intl = useIntl();

  const pageContent = () => {
    return(
      <div className='col-lg-8'>
        <div className="mobile-container">
        <Link to="/mycars/update-car/" state={{id: location.state ? location.state.id : ""}} className="black mb-4 back-link"><span className="icon icon-chevron"></span><strong>{intl.formatMessage({id: "generic.nav_buttons.back_button"})}</strong></Link>
      </div>
      <AddCarLastService navigateTo='/mycars/my-cars'/>
      </div>
    )
  };

  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.mycars.last-service" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="my-cars" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>
    </Layout>
  )
};

export default LastService
